export const setupNav = () => {
  const mainNav = document.querySelector('.main-nav');
  const navSlideToggle = document.getElementById('nav-slide-toggle');
  navSlideToggle.addEventListener('click', () => mainNav.classList.toggle('is-collapsed'));

  const navMenuToggle = document.getElementById('nav-menu-toggle');
  navMenuToggle.addEventListener('click', () => mainNav.classList.toggle('is-open'));

  const userNav = document.querySelector('.user-menu');
  const userMenuToggle = document.getElementById('user-menu-toggle');
  if (userMenuToggle) {
    userMenuToggle.addEventListener('click', () => userNav.classList.toggle('is-open'));
  }
};
