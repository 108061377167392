import { library, dom } from '@fortawesome/fontawesome-svg-core';

const customGiftIcon = {
  prefix: 'cra',
  iconName: 'gift',
  icon: [
    21.95,
    27.85,
    [],
    'e001',
    'M20.75,27.85H1.36V12.62H20.75ZM22,7.6H0v4.05H22Zm-9.81.05a1.43,1.43,0,0,1-1-.31,2.38,2.38,0,0,1-.37-2.44,7.24,7.24,0,0,1,1.43-2.8c.86-1,2.91-2.72,4.49-1.87a3.17,3.17,0,0,1,1.18,3.58,4.33,4.33,0,0,1-2.14,2.3l-.21-.31.2.31A7.59,7.59,0,0,1,12.14,7.65ZM15.8.75a4.92,4.92,0,0,0-3,1.83,6.52,6.52,0,0,0-1.27,2.5c-.2.8-.15,1.45.13,1.68s1,.5,3.68-1.28h0c1-.64,1.59-1.07,1.83-1.88A2.49,2.49,0,0,0,16.39.89,1.2,1.2,0,0,0,15.8.75Zm0,5.36-.21-.31.21.31ZM9.63,7.65a9.05,9.05,0,0,1-4-1.53,4.56,4.56,0,0,1-2.39-2.3A3.06,3.06,0,0,1,4.52.22c1.73-.83,4,.86,4.94,1.86a6.09,6.09,0,0,1,1.75,4.1,1.55,1.55,0,0,1-.57,1.17h0A1.62,1.62,0,0,1,9.63,7.65ZM5.54.75A1.58,1.58,0,0,0,4.85.9a2.32,2.32,0,0,0-.92,2.69c.27.8.95,1.24,2.06,1.88C9,7.22,9.93,7,10.19,6.75a.81.81,0,0,0,.27-.63A5.32,5.32,0,0,0,8.92,2.6,5.7,5.7,0,0,0,5.54.75Z'
  ]
};

library.add([customGiftIcon]);

dom.i2svg();
