import Tablesort from 'tablesort';
import parse from 'date-fns/parse';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';

export const sortTables = () => {
  const extensions = {
    lastName: () => {
      Tablesort.extend(
        'lastName',
        item => /\w+(\s+\w+)+/.test(item),
        (a, b) => {
          const lastName1 = a
            .trim()
            .split(' ')
            .pop();
          const lastName2 = b
            .trim()
            .split(' ')
            .pop();

          if (lastName1 === lastName2) {
            return 0;
          }
          return lastName1 > lastName2 ? -1 : 1;
        }
      );
    },
    shortDate: () => {
      Tablesort.extend(
        'shortDate',
        item => /\w{3}\s+\d{1,2},\s+\d{4}/.test(item),
        (a, b) => {
          const date1 = parse(a.trim().replace(/\s+/, ' '), 'MMM d, yyyy', new Date());
          const date2 = parse(b.trim().replace(/\s+/, ' '), 'MMM d, yyyy', new Date());

          if (isEqual(date1, date2)) {
            return 0;
          }
          return isAfter(date1, date2) ? -1 : 1;
        }
      );
    }
  };

  const tables = document.querySelectorAll('table[data-sort]');
  tables.forEach(table => {
    const { extensions: extensionList = '' } = table.dataset;
    extensionList
      .split('|')
      .filter(loader => loader !== '')
      .forEach(loader => extensions[loader]());

    return new Tablesort(table);
  });
};
